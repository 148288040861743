import React, { useEffect } from 'react'
import { GetStaticProps } from 'next'
import { isAcademyExAu, isAcademyexApp } from '../lib/detectEnvironment'
import ContentPage, {
  ContentPageProps,
  generateStaticContentPageProps,
} from '../components/ContentPage/ContentPage'
import { homePageVersion } from '../types/types'
import { getCookie, deleteCookie } from 'cookies-next'
import { useUser } from '@auth0/nextjs-auth0/client'
const IndexPage = ({ page, menu, footer, graphqlResources, slug }: ContentPageProps) => {
  const { user } = useUser()

  useEffect(() => {
    // Delete the AB testing cookie, we can remove this once we consider it appropriate
    if (isAcademyexApp()) {
      const cookie = getCookie('aexABTest')

      if (cookie?.length) {
        deleteCookie('aexABTest')
      }
    }
  }, [])

  return (
    <ContentPage
      page={page}
      menu={menu}
      footer={footer}
      graphqlResources={graphqlResources}
      slug={slug}
    />
  )
}
export default IndexPage

export const getStaticProps: GetStaticProps<ContentPageProps> = async () => {
  const isAexAu = isAcademyExAu()

  const indexSlug = isAexAu ? homePageVersion.SUBDOMAIN : homePageVersion.DEFAULT

  return generateStaticContentPageProps(indexSlug)
}
